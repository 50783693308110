// This file is generated. Modify the build script if you need to change it.

package dev.bitspittle.site.pages.blog

import androidx.compose.runtime.*
import com.varabyte.kobweb.core.Page
import dev.bitspittle.site.components.layouts.PageLayout
import dev.bitspittle.site.components.widgets.blog.ArticleEntry
import dev.bitspittle.site.components.widgets.blog.ArticleList

@Page
@Composable
fun BlogListingsPage() {
  PageLayout("Blog Posts") {
    val entries = listOf(
      ArticleEntry("/blog/2024/c4w", "David Herman", "2024-07-09", "Compose Multiplatform for Web: An Amazing Framework That Maybe You Shouldn't Use", "Compose Multiplatform is a powerful framework for building apps in Kotlin using a shared codebase"),
      ArticleEntry("/blog/2023/cloud-deploy", "David Herman", "2023-05-07", "Deploying Kobweb into the Cloud", "How to use Kobweb to build a Compose HTML site that can be served by a Kobweb server living in the Cloud"),
      ArticleEntry("/blog/2022/kover-badge", "David Herman", "2022-10-12", "Creating a GitHub Code Coverage Badge using Kover", "How to create a code coverage badge for your GitHub README using JetBrains Kover."),
      ArticleEntry("/blog/2022/static-deploy", "David Herman", "2022-02-11", "Static Site Generation and Deployment with Kobweb", "How to use Kobweb to build a Compose HTML site that can be served by static site hosting providers for cheap (or free)!"),
      ArticleEntry("/blog/2022/kotlin-site", "David Herman", "2022-02-07", "Kobweb: A Framework Built on Compose HTML", "An intro to Kobweb, a Kotlin web framework I wrote and used to build this website."),
    )
    ArticleList(entries)
  }
}
