package dev.bitspittle.site.pages

import androidx.compose.runtime.*
import com.varabyte.kobweb.core.*
import com.varabyte.kobwebx.markdown.*

@Page("/")
@Composable
fun IndexPage() {
    CompositionLocalProvider(LocalMarkdownContext provides MarkdownContext("Index.md", mapOf())) {
        dev.bitspittle.site.components.layouts.PageLayout("Welcome to Bitspittle.dev") {
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Hello! My name is David Herman.")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("I am a programmer with 20 years of experience -- with the first half in the game industry and the second half at Google. I am currently taking a break to work on ")
                com.varabyte.kobweb.silk.components.navigation.Link("https://github.com/varabyte/kobweb") {
                    org.jetbrains.compose.web.dom.Text("Kobweb")
                }
                org.jetbrains.compose.web.dom.Text(".")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("My plan with this site is to share both interactive tutorials and tech career advice gleaned from a long, bumpy road full of needlessly dumb mistakes.")
            }
        }
    }
}
