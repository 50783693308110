package dev.bitspittle.site.pages.blog._2022

import androidx.compose.runtime.*
import com.varabyte.kobweb.core.*
import com.varabyte.kobwebx.markdown.*

@Page("/blog/2022/")
@Composable
fun KotlinSitePage() {
    CompositionLocalProvider(LocalMarkdownContext provides MarkdownContext("blog/2022/KotlinSite.md", mapOf("title" to listOf("Kobweb: A Framework Built on Compose HTML"), "description" to listOf("An intro to Kobweb, a Kotlin web framework I wrote and used to build this website."), "author" to listOf("David Herman"), "date" to listOf("2022-02-07"), "updated" to listOf("2024-05-17"), "tags" to listOf("compose html", "webdev", "kobweb")))) {
        dev.bitspittle.site.components.layouts.BlogLayout {
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("I wrote a thing -- a Kotlin web framework called ")
                com.varabyte.kobweb.silk.components.navigation.Link("https://github.com/varabyte/kobweb") {
                    org.jetbrains.compose.web.dom.Text("Kobweb")
                }
                org.jetbrains.compose.web.dom.Text(".")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("It is built on top of ")
                com.varabyte.kobweb.silk.components.navigation.Link("https://github.com/JetBrains/compose-multiplatform#compose-html") {
                    org.jetbrains.compose.web.dom.Text("Compose HTML")
                }
                org.jetbrains.compose.web.dom.Text(", an official reactive web UI framework created by JetBrains (in close collaboration with Google, and in turn built upon core technologies introduced in Android's Jetpack Compose).")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("And this whole site, ")
                org.jetbrains.compose.web.dom.Em {
                    org.jetbrains.compose.web.dom.Text("including this very page you are now perusing")
                }
                org.jetbrains.compose.web.dom.Text(", is Kobweb's first user.")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Frontend development in Kotlin is still in its early days, so it's an exciting time to explore the space. In this post, I'll introduce some Kobweb basics, as well as discuss why you might (or might not!) want to use it.")
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("kobweb") }) {
                org.jetbrains.compose.web.dom.Text("Kobweb")
                dev.bitspittle.site.components.widgets.navigation.HoverLink("#kobweb")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Compose HTML is a rich API that does an impressive amount of work wrapping underlying html / css concepts into a reactive API. However, it is ultimately a foundational layer, leaving many choices to the developer on how to approach the final design.")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("If instead you wanted to start writing Kotlin code immediately to create webpages, that's where Kobweb comes in.")
            }
            org.jetbrains.compose.web.dom.H3(attrs = { id("creating-a-page") }) {
                org.jetbrains.compose.web.dom.Text("Creating a page")
                dev.bitspittle.site.components.widgets.navigation.HoverLink("#creating-a-page")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Let's say you recently picked up the domain ")
                dev.bitspittle.site.components.widgets.code.InlineCode("""https://example.com""")
                org.jetbrains.compose.web.dom.Text(". One of the very first things you might want to do is create the page ")
                dev.bitspittle.site.components.widgets.code.InlineCode("""https://example.com/hello""")
                org.jetbrains.compose.web.dom.Text(".")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("With Kobweb, this couldn't be easier -- just annotate a composable method with the ")
                dev.bitspittle.site.components.widgets.code.InlineCode("""@Page""")
                org.jetbrains.compose.web.dom.Text(" annotation, and you're done:")
            }
            dev.bitspittle.site.components.widgets.code.CodeBlock("""// src/jsMain/kotlin/com/example/pages/Hello.kt
package com.example.pages

@Page
@Composable
fun HelloPage() {
    Text("Hello, World!")
}
""", lang = "kotlin")
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("That's it! Really!")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("To test it, spin up a Kobweb server (using ")
                dev.bitspittle.site.components.widgets.code.InlineCode("""kobweb run""")
                org.jetbrains.compose.web.dom.Text("), visit ")
                dev.bitspittle.site.components.widgets.code.InlineCode("""http://localhost:8080/hello""")
                org.jetbrains.compose.web.dom.Text(" in your browser, and enjoy your working Kobweb site.")
            }
            org.jetbrains.compose.web.dom.H3(attrs = { id("linking-pages") }) {
                org.jetbrains.compose.web.dom.Text("Linking pages")
                dev.bitspittle.site.components.widgets.navigation.HoverLink("#linking-pages")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Once you have at least two pages, you can navigate between them using a ")
                dev.bitspittle.site.components.widgets.code.InlineCode("""Link""")
                org.jetbrains.compose.web.dom.Text(". The page transition will happen instantly, without needing to fetch additional information from the server.")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("In other words, if we add this \"goodbye\" page:")
            }
            dev.bitspittle.site.components.widgets.code.CodeBlock("""// src/jsMain/kotlin/com/example/pages/Goodbye.kt
package com.example.pages

@Page
@Composable
fun GoodbyePage() {
    Text("Goodbye, Cruel World!")
}
""", lang = "kotlin")
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("we can then modify our \"hello\" page example to add a link to it:")
            }
            dev.bitspittle.site.components.widgets.code.CodeBlock("""// src/jsMain/kotlin/com/example/pages/Hello.kt
package com.example.pages

@Page
@Composable
fun HelloPage() {
    Text("Hello, World!")
    Link("/goodbye", "Say goodbye...")
}
""", lang = "kotlin")
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("With this setup, ")
                dev.bitspittle.site.components.widgets.code.InlineCode("""https://example.com/hello""")
                org.jetbrains.compose.web.dom.Text(" will now show a link which, if clicked, will switch your page instantly to ")
                dev.bitspittle.site.components.widgets.code.InlineCode("""https://example.com/goodbye""")
                org.jetbrains.compose.web.dom.Text(".")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("If you pass an external address to ")
                dev.bitspittle.site.components.widgets.code.InlineCode("""Link""")
                org.jetbrains.compose.web.dom.Text(", e.g. ")
                dev.bitspittle.site.components.widgets.code.InlineCode("""Link("https://google.com")""")
                org.jetbrains.compose.web.dom.Text(", then it will act like a normal html link and navigate to that page as you'd expect.")
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("silk") }) {
                org.jetbrains.compose.web.dom.Text("Silk")
                dev.bitspittle.site.components.widgets.navigation.HoverLink("#silk")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Kobweb can be used on its own for its routing capabilities, but it also provides a UI library called Silk, a color-mode-aware (i.e. light and dark) collection of widgets as well as general theming and CSS styling support via CSS style blocks.")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("I believe CSS style blocks are one of those features that once you start using them, you won't want to go back. I demonstrate it later in its ")
                com.varabyte.kobweb.silk.components.navigation.Link("#css-style-blocks") {
                    org.jetbrains.compose.web.dom.Text("own subsection▼")
                }
                org.jetbrains.compose.web.dom.Text(".")
            }
            org.jetbrains.compose.web.dom.H3(attrs = { id("color-mode") }) {
                org.jetbrains.compose.web.dom.Text("Color mode")
                dev.bitspittle.site.components.widgets.navigation.HoverLink("#color-mode")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Did you happen to see the color toggling button at the top-right of the site? No need to move your cursor -- I'll create another copy here: ")
                dev.bitspittle.site.components.widgets.button.ColorModeButton()
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("This button encapsulates the logic for changing this site's active color mode. Try clicking on it!")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("It's trivial to query your site's color mode. Silk exposes a ")
                dev.bitspittle.site.components.widgets.code.InlineCode("""ColorMode.current""")
                org.jetbrains.compose.web.dom.Text(" property:")
            }
            dev.bitspittle.site.components.widgets.code.CodeBlock("""@Composable
fun SomeWidget() {
    val colorMode = ColorMode.current
    val widgetColor =
        if (colorMode.isDark) Colors.Pink else Colors.Red
    /* ... code that uses widgetColor ... */
}
""", lang = "kotlin")
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("You can also use ")
                dev.bitspittle.site.components.widgets.code.InlineCode("""var""")
                org.jetbrains.compose.web.dom.Text(" instead of ")
                dev.bitspittle.site.components.widgets.code.InlineCode("""val""")
                org.jetbrains.compose.web.dom.Text(" with ")
                dev.bitspittle.site.components.widgets.code.InlineCode("""ColorMode.currentState""")
                org.jetbrains.compose.web.dom.Text(" in your code, if you want to change the color mode, not just read it:")
            }
            dev.bitspittle.site.components.widgets.code.CodeBlock("""@Composable
fun ToggleColorButton() {
    var colorMode by ColorMode.currentState
    Button(onClick = { colorMode = colorMode.opposite })
}
""", lang = "kotlin")
            org.jetbrains.compose.web.dom.H3(attrs = { id("canvas") }) {
                org.jetbrains.compose.web.dom.Text("Canvas")
                dev.bitspittle.site.components.widgets.navigation.HoverLink("#canvas")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("So far, most of this post has been text. But honestly -- text is static. How droll.")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("This is the future! Users of the web3 era demand more.")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Using Kotlin, you can create dynamic elements by rendering to a canvas. The following clock is adapted from ")
                com.varabyte.kobweb.silk.components.navigation.Link("https://developer.mozilla.org/en-US/docs/Web/API/Canvas_API/Tutorial/Basic_animations#an_animated_clock") {
                    org.jetbrains.compose.web.dom.Text("this Mozilla canvas example")
                }
                org.jetbrains.compose.web.dom.Text(" that was originally written in JavaScript.")
            }
            dev.bitspittle.site.components.widgets.blog.kotlinsite.DemoWidget()
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("It additionally renders differently depending on the site's color mode. You can click this color mode button ")
                dev.bitspittle.site.components.widgets.button.ColorModeButton()
                org.jetbrains.compose.web.dom.Text(" to observe the results yourself.")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Here's the ")
                com.varabyte.kobweb.silk.components.navigation.Link("https://github.com/bitspittle/bitspittle.dev/blob/b5ce2d5a53e2017a6bd89b55dd6e855634587d51/src/jsMain/kotlin/dev/bitspittle/site/components/widgets/blog/kotlinsite/DemoWidget.kt#L39") {
                    org.jetbrains.compose.web.dom.Text("Kotlin source")
                }
                org.jetbrains.compose.web.dom.Text(".")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Among other things, Silk provides a helpful ")
                dev.bitspittle.site.components.widgets.code.InlineCode("""Canvas2d""")
                org.jetbrains.compose.web.dom.Text(" widget which makes it easy to register some code that will automatically get called for you once per frame.")
            }
            dev.bitspittle.site.components.widgets.code.CodeBlock("""@Composable
private fun Clock() {
  Canvas2d(300, 300, minDeltaMs = ONE_FRAME_MS_60_FPS) {
    /* This callback handles one frame of canvas rendering. */
  }
}
""", lang = "kotlin")
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Despite being easy to use, the canvas widget is extremely powerful, and you could use it to create dynamic effects, full screen backgrounds, or even games.")
            }
            org.jetbrains.compose.web.dom.H3(attrs = { id("modifier") }) {
                org.jetbrains.compose.web.dom.Text("Modifier")
                dev.bitspittle.site.components.widgets.navigation.HoverLink("#modifier")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Anyone who has dabbled with Jetpack Compose is likely familiar with the ")
                dev.bitspittle.site.components.widgets.code.InlineCode("""Modifier""")
                org.jetbrains.compose.web.dom.Text(" class. It may seem as fundamental to Compose as the ")
                dev.bitspittle.site.components.widgets.code.InlineCode("""@Composable""")
                org.jetbrains.compose.web.dom.Text(" annotation is.")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("However, it isn't! Compose HTML actually does not have a ")
                dev.bitspittle.site.components.widgets.code.InlineCode("""Modifier""")
                org.jetbrains.compose.web.dom.Text(" class.")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Instead, it uses an approach where all HTML tags are converted to ")
                dev.bitspittle.site.components.widgets.code.InlineCode("""@Composable""")
                org.jetbrains.compose.web.dom.Text(" function calls that take in something called an ")
                dev.bitspittle.site.components.widgets.code.InlineCode("""AttrsScope""")
                org.jetbrains.compose.web.dom.Text(".")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("As a concrete example, this HTML document tag:")
            }
            dev.bitspittle.site.components.widgets.code.CodeBlock("""<div
   id="example"
   style="width:50px;height:25px;background-color:black"
>
""", lang = "html")
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("would be written with the following Compose HTML code:")
            }
            dev.bitspittle.site.components.widgets.code.CodeBlock("""Div(attrs = {
    assert(this is AttrsScope)
    id("example")
    style {
        width(50.px)
        height(25.px)
        backgroundColor("black")
    }
})
""", lang = "kotlin")
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("I think this approach is pretty neat, but as ")
                dev.bitspittle.site.components.widgets.code.InlineCode("""AttrsScope""")
                org.jetbrains.compose.web.dom.Text(" is a mutable class, that makes it dangerous to store in a shared variable. Plus, its API doesn't support chaining.")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("To solve this, Silk provides its own ")
                dev.bitspittle.site.components.widgets.code.InlineCode("""Modifier""")
                org.jetbrains.compose.web.dom.Text(" class which is ")
                org.jetbrains.compose.web.dom.Em {
                    org.jetbrains.compose.web.dom.Text("inspired")
                }
                org.jetbrains.compose.web.dom.Text(" by Jetpack Compose's version but isn't exactly the same one. Still, it should look familiar enough to people who write Jetpack Compose code.")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("The above Compose HTML ")
                dev.bitspittle.site.components.widgets.code.InlineCode("""AttrsScope""")
                org.jetbrains.compose.web.dom.Text(" would be represented by the following ")
                dev.bitspittle.site.components.widgets.code.InlineCode("""Modifier""")
                org.jetbrains.compose.web.dom.Text(":")
            }
            dev.bitspittle.site.components.widgets.code.CodeBlock("""private val EXAMPLE_MODIFIER = Modifier
    .id("example")
    .width(50.px).height(25.px)
    .backgroundColor(Colors.Black)
""", lang = "kotlin")
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Silk widgets take modifiers directly:")
            }
            dev.bitspittle.site.components.widgets.code.CodeBlock("""Button(
    onClick = { /*...*/ },
    modifier = EXAMPLE_MODIFIER
)
""", lang = "kotlin")
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("But for interoperability with Compose HTML elements, it is easy to convert a ")
                dev.bitspittle.site.components.widgets.code.InlineCode("""Modifier""")
                org.jetbrains.compose.web.dom.Text(" into an ")
                dev.bitspittle.site.components.widgets.code.InlineCode("""AttrsScope""")
                org.jetbrains.compose.web.dom.Text(" on the fly, using the ")
                dev.bitspittle.site.components.widgets.code.InlineCode("""toAttrs""")
                org.jetbrains.compose.web.dom.Text(" method:")
            }
            dev.bitspittle.site.components.widgets.code.CodeBlock("""Div(attrs = EXAMPLE_MODIFIER.toAttrs())
""", lang = "kotlin")
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("With ")
                dev.bitspittle.site.components.widgets.code.InlineCode("""Modifier""")
                org.jetbrains.compose.web.dom.Text("s, chaining is easy using the ")
                dev.bitspittle.site.components.widgets.code.InlineCode("""then""")
                org.jetbrains.compose.web.dom.Text(" method:")
            }
            dev.bitspittle.site.components.widgets.code.CodeBlock("""private val SIZE_MODIFIER = Modifier.size(50.px)
private val SPACING_MODIFIER = Modifier.margin(10.px).padding(20.px)

private val COMBINED_MODIFIER = SIZE_MODIFIER.then(SPACING_MODIFIER)
""", lang = "kotlin")
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Modifiers are used heavily throughout Silk, which should help ease the experience for Android and desktop Kotlin developers just getting started with frontend development.")
            }
            org.jetbrains.compose.web.dom.H3(attrs = { id("organizing-styles") }) {
                org.jetbrains.compose.web.dom.Text("Organizing styles")
                dev.bitspittle.site.components.widgets.navigation.HoverLink("#organizing-styles")
            }
            org.jetbrains.compose.web.dom.H4(attrs = { id("stylesheet-shortcomings") }) {
                org.jetbrains.compose.web.dom.Text("Stylesheet shortcomings")
                dev.bitspittle.site.components.widgets.navigation.HoverLink("#stylesheet-shortcomings")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Most frontend projects have a single, giant, terrifying stylesheet (or, worse, several giant, terrifying stylesheets) driving the look and feel of their site.")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Em {
                    org.jetbrains.compose.web.dom.B {
                        org.jetbrains.compose.web.dom.Text("Aside:")
                    }
                    org.jetbrains.compose.web.dom.Text(" If you don't know what a stylesheet is, it's a collection of CSS rules that target various elements on your page, specifying their style using a declarative format.")
                }
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("For example, at one point while working on Kobweb, I used a todo app to learn from, and at least half of the time I spent was crawling over ")
                com.varabyte.kobweb.silk.components.navigation.Link("https://github.com/upstash/redis-examples/blob/master/nextjs-todo/styles/Home.module.css") {
                    org.jetbrains.compose.web.dom.Text("their stylesheet")
                }
                org.jetbrains.compose.web.dom.Text(" to understand the nuances of their approach.")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Compose HTML allows you to ")
                com.varabyte.kobweb.silk.components.navigation.Link("https://github.com/JetBrains/compose-jb/tree/master/tutorials/HTML/Style_Dsl#stylesheet") {
                    org.jetbrains.compose.web.dom.Text("define this stylesheet in code")
                }
                org.jetbrains.compose.web.dom.Text(", but you can still easily end up with a monolith.")
            }
            org.jetbrains.compose.web.dom.H4(attrs = { id("css-style-blocks") }) {
                org.jetbrains.compose.web.dom.Text("CSS style blocks")
                dev.bitspittle.site.components.widgets.navigation.HoverLink("#css-style-blocks")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Kobweb introduces CSS style blocks, which is a fancy way of saying you can define the styles you use in smaller pieces next to the code that uses them.")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("It's easy -- just instantiate a ")
                dev.bitspittle.site.components.widgets.code.InlineCode("""CssStyle""")
                org.jetbrains.compose.web.dom.Text(" and store the result to a ")
                dev.bitspittle.site.components.widgets.code.InlineCode("""val""")
                org.jetbrains.compose.web.dom.Text(":")
            }
            dev.bitspittle.site.components.widgets.code.CodeBlock("""val HoverContainerStyle = CssStyle {
    base { Modifier.fontSize(32.px).padding(10.px) }
    hover {
        val highlightColor =
            if (colorMode.isDark) Colors.Pink else Colors.Red
        Modifier.backgroundColor(highlightColor)
    }
}
""", lang = "kotlin")
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("The ")
                dev.bitspittle.site.components.widgets.code.InlineCode("""base""")
                org.jetbrains.compose.web.dom.Text(" style, if defined, is special, as it will always be applied first. Any additional declarations are layered on top of the base if their condition is met.")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("CSS styles can be converted to ")
                dev.bitspittle.site.components.widgets.code.InlineCode("""Modifier""")
                org.jetbrains.compose.web.dom.Text("s using the ")
                dev.bitspittle.site.components.widgets.code.InlineCode("""toModifier""")
                org.jetbrains.compose.web.dom.Text(" method and to ")
                dev.bitspittle.site.components.widgets.code.InlineCode("""AttrsScope""")
                org.jetbrains.compose.web.dom.Text("s using the ")
                dev.bitspittle.site.components.widgets.code.InlineCode("""toAttrs""")
                org.jetbrains.compose.web.dom.Text(" method. This way, you can pass them into either Silk widgets ")
                org.jetbrains.compose.web.dom.Em {
                    org.jetbrains.compose.web.dom.Text("or")
                }
                org.jetbrains.compose.web.dom.Text(" Compose HTML elements:")
            }
            dev.bitspittle.site.components.widgets.code.CodeBlock("""val HoverContainerStyle = CssStyle { /*...*/ }

// Then later...

// Silk widget:
Box(HoverContainerStyle.toModifier()) { /*...*/ }
    
// Compose HTML element:
Div(attrs = HoverContainerStyle.toAttrs()) { /*...*/ }
""", lang = "kotlin")
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("It is way easier to read your code when your element styles live near where they are used, since you don't have to jump between the code and a monolithic stylesheet in a different file.")
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("markdown") }) {
                org.jetbrains.compose.web.dom.Text("Markdown")
                dev.bitspittle.site.components.widgets.navigation.HoverLink("#markdown")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("At the beginning of this post, I said this site was written entirely in Kotlin. This may actually be a technicality.")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("In fact, most of this site is written using markdown. Relevant markdown files are transpiled to Kotlin just before compilation happens.")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Kobweb extends Markdown with some custom support for nesting code inside it which is how I embedded the color buttons and clock widget above. You can inline code with a Kotlin-y ")
                dev.bitspittle.site.components.widgets.code.InlineCode("""${'$'}{...}""")
                org.jetbrains.compose.web.dom.Text(" syntax or put a larger widget on its own line with triple curly-brace syntax:")
            }
            dev.bitspittle.site.components.widgets.code.CodeBlock("""# An intro to pathfinding

Here is a demonstration of A-star pathfinding

{{{ .components.widgets.astar.Demo }}}

Play: ${'$'}{.components.widgets.astar.PlayButton}
Step: ${'$'}{.components.widgets.astar.StepButton}
""", lang = "markdown")
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Code references that start with ")
                dev.bitspittle.site.components.widgets.code.InlineCode(""".""")
                org.jetbrains.compose.web.dom.Text(" will automatically be prefixed by your project's base package, so for example all the code references above would generate final code prefixed with something like ")
                dev.bitspittle.site.components.widgets.code.InlineCode("""com.example""")
                org.jetbrains.compose.web.dom.Text(" (but whatever is used by your project).")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("You can see ")
                com.varabyte.kobweb.silk.components.navigation.Link("https://github.com/bitspittle/bitspittle.dev/blob/main/site/src/jsMain/resources/markdown/blog/2022/KotlinSite.md") {
                    org.jetbrains.compose.web.dom.Text("the markdown for this blog post")
                }
                org.jetbrains.compose.web.dom.Text(" for yourself!")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Ultimately, Markdown support out-of-the-box means that if you love Kotlin ")
                org.jetbrains.compose.web.dom.Em {
                    org.jetbrains.compose.web.dom.Text("and")
                }
                org.jetbrains.compose.web.dom.Text(" you were thinking of starting a blog, Kobweb might be a great solution for you.")
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("other-approaches") }) {
                org.jetbrains.compose.web.dom.Text("Other approaches")
                dev.bitspittle.site.components.widgets.navigation.HoverLink("#other-approaches")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Let's finish off by discussing other approaches, to compare and contrast with Kobweb.")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("If you're already sold on Kobweb, feel free to skip this section and jump straight to the ")
                com.varabyte.kobweb.silk.components.navigation.Link("#conclusion") {
                    org.jetbrains.compose.web.dom.Text("conclusion▼")
                }
                org.jetbrains.compose.web.dom.Text(".")
            }
            org.jetbrains.compose.web.dom.H3(attrs = { id("compose-multiplatform") }) {
                org.jetbrains.compose.web.dom.Text("Compose Multiplatform")
                dev.bitspittle.site.components.widgets.navigation.HoverLink("#compose-multiplatform")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Many users in the Kotlin community are excited about the promise of multiplatform, and they want to write an app once and run it everywhere (Android, iOS, Desktop, ")
                org.jetbrains.compose.web.dom.Em {
                    org.jetbrains.compose.web.dom.Text("and")
                }
                org.jetbrains.compose.web.dom.Text(" Web).")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Kobweb is very much ")
                org.jetbrains.compose.web.dom.Em {
                    org.jetbrains.compose.web.dom.Text("not")
                }
                org.jetbrains.compose.web.dom.Text(" that sort of solution. It is designed for developers who want to create a traditional website but use Kotlin instead of, say, TypeScript.")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Kobweb (and Compose HTML) interact with the DOM and let the browser handle rendering it. In contrast, Compose Multiplatform for Web works by creating an HTML canvas and then rendering your app to it opaquely. If what you really want to do is write a cross-platform app which just happens to also work in your browser, then Compose Multiplatform is probably the solution for you.")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("There's no one-size fits all solution, however, and Kobweb may still be the right choice if you're creating a website. I wrote about this a bit more in ")
                com.varabyte.kobweb.silk.components.navigation.Link("https://github.com/varabyte/kobweb#what-about-compose-for-web-canvas") {
                    org.jetbrains.compose.web.dom.Text("Kobweb's README")
                }
                org.jetbrains.compose.web.dom.Text(", in case you wanted to learn more about the different approaches, as well as why you might still choose a traditional DOM API in a multiplatform world.")
            }
            org.jetbrains.compose.web.dom.H3(attrs = { id("vanilla-compose-html") }) {
                org.jetbrains.compose.web.dom.Text("Vanilla Compose HTML")
                dev.bitspittle.site.components.widgets.navigation.HoverLink("#vanilla-compose-html")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Perhaps you've been burned by frameworks before. \"Yeah buddy, Kobweb is nice, but I'm just going to stick with Compose HTML ")
                org.jetbrains.compose.web.dom.Em {
                    org.jetbrains.compose.web.dom.Text("classic")
                }
                org.jetbrains.compose.web.dom.Text(".\"")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("That's fine with me! Just be aware, this post only scratched the surface of what Kobweb can do for you. Here's a fuller list of features we provide, since if you go it alone, you may need to implement some of them yourself:")
            }
            org.jetbrains.compose.web.dom.Ul {
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("setting up Gradle build files and index.html boilerplate")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("site routing")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("running and configuring a server")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("defining and communicating with server API routes")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("site exports, for SEO and/or serving pages of your site statically")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("organizing your stylesheets")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("light and dark color mode support and theming")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("a (growing) collection of color-mode aware widgets")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("introduction of the ")
                    dev.bitspittle.site.components.widgets.code.InlineCode("""Modifier""")
                    org.jetbrains.compose.web.dom.Text(" concept, useful for chaining styles")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("implementations for ")
                    dev.bitspittle.site.components.widgets.code.InlineCode("""Box""")
                    org.jetbrains.compose.web.dom.Text(", ")
                    dev.bitspittle.site.components.widgets.code.InlineCode("""Column""")
                    org.jetbrains.compose.web.dom.Text(", and ")
                    dev.bitspittle.site.components.widgets.code.InlineCode("""Row""")
                    org.jetbrains.compose.web.dom.Text(" on top of html / css")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("a ton of CSS properties not found in Compose HTML")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("many utility methods and classes for working with the DOM")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Markdown support")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("WebSocket support via API streams")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Web worker support via Kobweb Workers")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("shape clipping")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("composables for all free Font Awesome ")
                    org.jetbrains.compose.web.dom.Em {
                        org.jetbrains.compose.web.dom.Text("and")
                    }
                    org.jetbrains.compose.web.dom.Text(" Material Design icons")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("parsing and handling query parameters (e.g. ")
                    dev.bitspittle.site.components.widgets.code.InlineCode("""/posts?userId=...&postId=...""")
                    org.jetbrains.compose.web.dom.Text(")")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("parsing and handling dynamic routes (e.g. ")
                    dev.bitspittle.site.components.widgets.code.InlineCode("""/users/{userId}/posts/{postId}""")
                    org.jetbrains.compose.web.dom.Text(")")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("handling responsive layouts (mobile vs. desktop)")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("an experience built from the ground up around live reloading")
                }
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("I mention these not (just) to humblebrag, but because I myself was surprised by what was needed to create an MVP of Kobweb. I vastly underestimated the scope.")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("So, sure, I'm biased, but my opinion is that if you're going to use Compose HTML to make a website (as opposed to a web app), you probably want to at least give Kobweb a try.")
            }
            org.jetbrains.compose.web.dom.H3(attrs = { id("javascript-typescript") }) {
                org.jetbrains.compose.web.dom.Text("JavaScript / TypeScript")
                dev.bitspittle.site.components.widgets.navigation.HoverLink("#javascript-typescript")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Kotlin/JS may not be for everyone. Most of the webdev community is amassed around JavaScript / TypeScript and libraries like React.")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("There are a lot of advantages to sticking with the crowd in this case. And not just because they have a huge headstart. Compile times tend to be a lot faster, you can experiment with JavaScript by typing commands directly in your browser, you'll benefit from a ton of community support and resources, and there's no shortage of interesting projects out there to learn from.")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("I have talked to many TypeScript programmers who vouch for it and say they enjoy writing code in the language. Microsoft has really done a great job adding seatbelts, helmets, and full body cushions to JavaScript (which itself is still evolving and getting better over time).")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("While I personally want to encourage more Kotlin developers to explore the frontend world and grow the community, if a new programmer came up to me today saying they wanted to write a website from scratch, especially with the hopes of developing skills that will turn into a frontend career, then I would send them to JavaScript / TypeScript tutorials at this point.")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("If you like what I'm doing with Kobweb but think at this time it makes more sense to use JavaScript / TypeScript for your project, check out ")
                com.varabyte.kobweb.silk.components.navigation.Link("https://nextjs.org/") {
                    org.jetbrains.compose.web.dom.Text("Next.js")
                }
                org.jetbrains.compose.web.dom.Text(" paired with ")
                com.varabyte.kobweb.silk.components.navigation.Link("https://chakra-ui.com/") {
                    org.jetbrains.compose.web.dom.Text("Chakra UI")
                }
                org.jetbrains.compose.web.dom.Text(", as both of these solutions were huge inspirations for me.")
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("conclusion") }) {
                org.jetbrains.compose.web.dom.Text("Conclusion")
                dev.bitspittle.site.components.widgets.navigation.HoverLink("#conclusion")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("I've been very excited about the Kotlin webdev space ever since Compose HTML was announced, and I hope this post has pushed at least one other person over the fence.")
            }
            org.jetbrains.compose.web.dom.H3(attrs = { id("trying-kobweb") }) {
                org.jetbrains.compose.web.dom.Text("Trying Kobweb")
                dev.bitspittle.site.components.widgets.navigation.HoverLink("#trying-kobweb")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("If Kobweb looks like something you'd want to play with, the easiest way to start is by ")
                com.varabyte.kobweb.silk.components.navigation.Link("https://github.com/varabyte/kobweb#install-the-kobweb-binary") {
                    org.jetbrains.compose.web.dom.Text("installing the Kobweb binary")
                }
                org.jetbrains.compose.web.dom.Text(".")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Once installed, you can run:")
            }
            dev.bitspittle.site.components.widgets.code.CodeBlock("""${'$'} kobweb create app
# answer a bunch of questions about your project
${'$'} cd app/site
${'$'} kobweb run
""", lang = "bash")
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Or if this post just made you curious about Compose HTML, you can start with the ")
                com.varabyte.kobweb.silk.components.navigation.Link("https://github.com/JetBrains/compose-jb/tree/master/tutorials/HTML/Getting_Started") {
                    org.jetbrains.compose.web.dom.Text("official tutorial")
                }
                org.jetbrains.compose.web.dom.Text(" but have Kobweb set it up for you in a few seconds:")
            }
            dev.bitspittle.site.components.widgets.code.CodeBlock("""${'$'} kobweb create examples/jb/counter
${'$'} cd counter
${'$'} kobweb run
""", lang = "bash")
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("And finally, if after reading this you are thinking about using Kobweb, consider jumping into our ")
                com.varabyte.kobweb.silk.components.navigation.Link("https://discord.gg/5NZ2GKV5Cs") {
                    org.jetbrains.compose.web.dom.Text("Discord server")
                }
                org.jetbrains.compose.web.dom.Text(", where I'd be happy to answer questions about Kobweb when I'm around.")
            }
            org.jetbrains.compose.web.dom.H3(attrs = { id("the-future") }) {
                org.jetbrains.compose.web.dom.Text("The future")
                dev.bitspittle.site.components.widgets.navigation.HoverLink("#the-future")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("I can't predict if Kotlin webdev will ever take off, much less Kobweb itself. But I sincerely want a future where there are more Kotlin developers owning codebases that cross the full stack. If I can help throw some code over the wall to help make the experience better, then I'm happy to have tried.")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("At the very least, Kobweb will always have one user -- this site!")
            }
        }
    }
}
