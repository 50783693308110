import androidx.compose.runtime.CompositionLocalProvider
import com.varabyte.kobweb.core.AppGlobals
import com.varabyte.kobweb.navigation.RoutePrefix
import com.varabyte.kobweb.navigation.Router
import com.varabyte.kobweb.navigation.UpdateHistoryMode
import com.varabyte.kobweb.silk.defer.renderWithDeferred
import kotlinx.browser.document
import kotlinx.browser.window
import org.jetbrains.compose.web.renderComposable

public fun main() {
    RoutePrefix.set("")
    val router = Router()
    com.varabyte.kobweb.core.init.initKobweb(router) { ctx ->
        ctx.router.register("/") { dev.bitspittle.site.pages.IndexPage() }
        ctx.router.register("/blog/") { dev.bitspittle.site.pages.blog.BlogListingsPage() }
        ctx.router.register("/blog/2022/kotlin-site") {
                dev.bitspittle.site.pages.blog._2022.KotlinSitePage() }
        ctx.router.register("/blog/2022/kover-badge") {
                dev.bitspittle.site.pages.blog._2022.KoverBadgePage() }
        ctx.router.register("/blog/2022/static-deploy") {
                dev.bitspittle.site.pages.blog._2022.StaticDeployPage() }
        ctx.router.register("/blog/2023/cloud-deploy") {
                dev.bitspittle.site.pages.blog._2023.CloudDeployPage() }
        ctx.router.register("/blog/2024/c4w") { dev.bitspittle.site.pages.blog._2024.C4WPage() }

    }
    router.addRouteInterceptor {
        path = path.removeSuffix(".html").removeSuffix(".htm")
    }

    com.varabyte.kobweb.silk.init.additionalSilkInitialization = { ctx ->
        com.varabyte.kobweb.silk.init.initSilkWidgets(ctx)
        com.varabyte.kobweb.silk.init.initSilkWidgetsKobweb(ctx)
        ctx.theme.registerStyle("bs-center-column",
                dev.bitspittle.site.components.layouts.CenterColumnStyle)
        ctx.theme.registerStyle("bs-footer", dev.bitspittle.site.components.sections.FooterStyle)
        ctx.theme.registerStyle("bs-copyright",
                dev.bitspittle.site.components.sections.CopyrightStyle)
        ctx.theme.registerStyle("bs-nav-header",
                dev.bitspittle.site.components.sections.NavHeaderStyle)
        ctx.theme.registerStyle("bs-nav-link", dev.bitspittle.site.components.sections.NavLinkStyle)
        ctx.theme.registerStyle("bs-nav-button",
                dev.bitspittle.site.components.sections.NavButtonStyle)
        ctx.theme.registerStyle("bs-article-list",
                dev.bitspittle.site.components.widgets.blog.ArticleListStyle)
        ctx.theme.registerStyle("bs-article-section",
                dev.bitspittle.site.components.widgets.blog.ArticleSectionStyle)
        ctx.theme.registerStyle("bs-article-title",
                dev.bitspittle.site.components.widgets.blog.ArticleTitleStyle)
        ctx.theme.registerStyle("bs-article-meta",
                dev.bitspittle.site.components.widgets.blog.ArticleMetaStyle)
        ctx.theme.registerStyle("bs-article-author",
                dev.bitspittle.site.components.widgets.blog.ArticleAuthorStyle)
        ctx.theme.registerStyle("bs-article-date",
                dev.bitspittle.site.components.widgets.blog.ArticleDateStyle)
        ctx.theme.registerStyle("bs-article-name",
                dev.bitspittle.site.components.widgets.blog.ArticleNameStyle)
        ctx.theme.registerStyle("bs-article-updated",
                dev.bitspittle.site.components.widgets.blog.ArticleUpdatedStyle)
        ctx.theme.registerStyle("bs-article-desc",
                dev.bitspittle.site.components.widgets.blog.ArticleDescStyle)
        ctx.theme.registerStyle("bs-code-block",
                dev.bitspittle.site.components.widgets.code.CodeBlockStyle)
        ctx.theme.registerStyle("bs-inline-code",
                dev.bitspittle.site.components.widgets.code.InlineCodeStyle)
        ctx.theme.registerStyle("bs-hover-link",
                dev.bitspittle.site.components.widgets.navigation.HoverLinkStyle)
        ctx.theme.registerVariant("-logo", dev.bitspittle.site.components.sections.LogoVariant
        )
        dev.bitspittle.site.initSilk(ctx)
        dev.bitspittle.site.components.layouts.initHighlightJs(ctx)
        dev.bitspittle.site.components.sections.initNavHeaderStyles(ctx)
        dev.bitspittle.site.components.widgets.navigation.initHeaderLinkInteraction(ctx)
    }

    // For SEO, we may bake the contents of a page in at build time. However, we will
    // overwrite them the first time we render this page with their composable, dynamic
    // versions. Think of this as poor man's hydration :)
    // See also: https://en.wikipedia.org/wiki/Hydration_(web_development)
    router.navigateTo(window.location.href.removePrefix(window.location.origin),
            UpdateHistoryMode.REPLACE)

    val root = document.getElementById("root")!!
    while (root.firstChild != null) { root.removeChild(root.firstChild!!) }

    AppGlobals.initialize(mapOf("title" to "BitSpittle.Dev"))
    renderComposable(rootElementId = "root") {
        dev.bitspittle.site.MyApp {
            router.renderActivePage { renderWithDeferred { it() } }
        }
    }
}
